const authBaseUrl =
  "https://id.staging.fennecplatform.com/auth/realms/Fennec/protocol/openid-connect";

const frontendBaseUri = process.env.REACT_APP_FRONTEND_BASE_URI;

export const Fennec = {
  REDIRECT_URI: `${frontendBaseUri}/oauth_callback`,
  SCOPE: "openid profile email",
  CLIENT_ID: "spa-pkce-example",
  AUTHORIZATION_ENDPOINT: `${authBaseUrl}/auth`,
  TOKEN_ENDPOINT: `${authBaseUrl}/token`
};

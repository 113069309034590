import React, { useEffect, useState } from "react";
import { Fennec } from "./config";

import jwt_decode from "jwt-decode";

// TODO: add logout flow

const LoginCallback = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const location = window.location;
  useEffect(() => {
    if (!isLoggedIn) {
      const code = (location.search.match(/code=([^&]+)/) || [])[1];

      // TODO: verify state is the same the was sent
      //const state = (location.search.match(/state=([^&]+)/) || [])[1];

      // TODO: generate code challenge / verifier dynamically
      const code_verifier =
        "xJ6VVRvfbkxMkfl40IMubz0lxzQrsAT6QevMD5FkILWG9Aqjy7f0nQch6B0KY.EnC71_y1DWkAIB7StZ~y.UxQ7FttZY8dn2WFG~zE27_zeogVFga3rE4mtmCPiw.7-D";

      const details = {
        grant_type: "authorization_code",
        redirect_uri: Fennec.REDIRECT_URI,
        client_id: Fennec.CLIENT_ID,
        code: code,
        code_verifier: code_verifier
      };

      const formBody = [];
      for (let property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }

      fetch(Fennec.TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody.join("&")
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.access_token) {
            setAccessToken(res.access_token);
            setIsLoggedIn(true);
          }
        })
        .catch(console.error);
    }
  }, [location.search, isLoggedIn]);

  const showUserInfo = (token) => {
    if (!isLoggedIn) {
      return (
        <div>
          <p>Not logged in...</p>
          <a href="/login">Go to login</a>
        </div>
      );
    }

    console.log("token");
    console.log(JSON.stringify(token));

    const decoded = jwt_decode(token);
    console.log(decoded);
    return (
      <div>
        <h2>User Info</h2>
        <p>username: {decoded.preferred_username}</p>
        <p>first name: {decoded.given_name}</p>
        <p>last name: {decoded.family_name}</p>
        <p>email: {decoded.email}</p>
        <a href="/login">Go to login</a>
      </div>
    );
  };

  return <div>{showUserInfo(accessToken)}</div>;
};

export default LoginCallback;

import React, { useCallback } from "react";
import { Card, Button } from "@material-ui/core";
import styled from "@emotion/styled";

import { Fennec } from "./config";

const StyledCard = styled(Card)`
  padding: 20px;
  margin: 100px auto;
  max-width: 40vw;
  min-width: 300px;
  Button {
    margin-bottom: 20px;
  }
`;

const Login = () => {
  const handleFennecLogin = useCallback(async () => {
    console.log({ authEndpoint: Fennec.AUTHORIZATION_ENDPOINT });

    const qParams = [
      `response_type=code`,
      `client_id=${Fennec.CLIENT_ID}`,
      `redirect_uri=${Fennec.REDIRECT_URI}`,
      `scope=${Fennec.SCOPE}`,
      `state=state123`,
      `access_type=offline`,
      `code_challenge=kCZ_wZU1RQ-iSLUK4A8FXGPBniQZhKLag0CDBpbxEdU`,
      `code_challenge_method=S256`
    ].join("&");
    try {
      const url = `${Fennec.AUTHORIZATION_ENDPOINT}?${qParams}`;
      window.location.assign(url);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <>
      <StyledCard>
        <Button variant="contained" color="primary" onClick={handleFennecLogin}>
          Login with Fennec
        </Button>
      </StyledCard>
    </>
  );
};

export default Login;

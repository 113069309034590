import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./components/Login";
import LoginCallback from "./components/Callback";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/oauth_callback" element={<LoginCallback />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
